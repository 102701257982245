import React from "react"
import styled from "styled-components"
import { LinkedinSquare, Twitter, Github } from "styled-icons/boxicons-logos"
import { Googlescholar } from "styled-icons/simple-icons"
import { trackEvent, TRACKING_CATEGORY, TRACKING_ACTION } from "./helpers"

const socialData = [
  {
    Icon: Twitter,
    href: "https://twitter.com/Donovan_TC",
    tracking: TRACKING_CATEGORY.TO_TWITTER,
    label: "Twitter",
  },
  {
    Icon: Github,
    href: "https://github.com/donovantc",
    tracking: TRACKING_CATEGORY.TO_GITHUB,
    label: "Github",
  },
  {
    Icon: LinkedinSquare,
    href: "https://www.linkedin.com/in/donovanisherwood/",
    tracking: TRACKING_CATEGORY.TO_LINKEDIN,
    label: "LinkedIn",
  },
  {
    Icon: Googlescholar,
    href: "https://scholar.google.com/citations?user=r5wLuzIAAAAJ&hl=en",
    tracking: TRACKING_CATEGORY.TO_GOOGLE_SCHOLAR,
    label: "Google Scholar",
  },
]

const StyledWrapper = styled.div`
  display: flex;
  a:first-child {
    margin-left: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    justify-content: center;
    margin-top: 1rem;
    a:first-child {
      margin-left: inherit;
    }
  }
`

const StyledIconLink = styled.a`
  display: block;
  margin: 0.3rem;
  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.color.white};
    :hover {
      color: ${(props) => props.theme.color.secondary};
    }
  }
`

export const SocialLinks = (props) => {
  const handleOnClick = (category) => (e) => {
    trackEvent(category, TRACKING_ACTION.CLICK, "Social Link from Hero")
  }

  return (
    <StyledWrapper>
      {socialData.map(({ Icon, href, tracking, label }, idx) => (
        <StyledIconLink
          key={`social_link_${idx}`}
          href={href}
          target="__blank"
          rel="noreferrer noopener"
          onClick={handleOnClick(tracking)}
        >
          <Icon role="img" aria-label={label} />
        </StyledIconLink>
      ))}
    </StyledWrapper>
  )
}
