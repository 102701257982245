import { trackCustomEvent } from "gatsby-plugin-google-analytics"

export const removeNull = (obj) =>
  Object.keys(obj)
    .filter((k) => obj[k] != null) // Remove undef. and null.
    .reduce(
      (newObj, k) =>
        typeof obj[k] === "object"
          ? { ...newObj, [k]: removeNull(obj[k]) } // Recurse.
          : { ...newObj, [k]: obj[k] }, // Copy value.
      {}
    )

export const TRACKING_CATEGORY = {
  TO_GITHUB: "to_github",
  TO_TWITTER: "to_twitter",
  TO_LINKEDIN: "to_linkedin",
  TO_GOOGLE_SCHOLAR: "to_google_scholar",
}

export const TRACKING_ACTION = {
  CLICK: "Click",
}

/**
 *
 * @param {*} category: TRACKING_CATEGORY - describes the event
 * @param {*} action: TRACKING_ACTION - type of interaction
 * @param {*} label: String - details about the location where the event occurred
 */
export const trackEvent = (category, action, label = "") => {
  trackCustomEvent({
    // string - required - The object that was interacted with (e.g.video)
    category,
    // string - required - Type of interaction (e.g. 'play')
    action,
    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
    label,
  })
}
